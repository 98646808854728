import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import EventPage from "../views/EventPage.vue";
import EventsPage from "../views/EventsPage.vue";
import SermonsPage from "../views/SermonsPage.vue";
import RegisterPage from "../views/RegisterPage.vue";

// Errors
import Error404Page from "../views/errors/Error404Page.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/events",
    name: "Events",
    component: EventsPage,
  },
  {
    path: "/event/:event",
    name: "EventPage",
    component: EventPage,
  },
  {
    path: "/sermons",
    name: "Sermons",
    component: SermonsPage,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: RegisterPage,
  // },
  // 404
  {
    path: "*",
    name: "404",
    component: Error404Page,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
