<template>
  <div id="SocialMedia">
    <h4>Social Media</h4>
    <ol class="list-unstyled">
      <li>
        <a
          href="https://www.youtube.com/@godanswersprayergroup/"
          target="_blank"
        >
          YouTube
        </a>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "SocialMedia",
  data() {
    return {};
  },
};
</script>

<style></style>
