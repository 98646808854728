<template>
  <div id="app">
    <Header />
    <!-- MAIN -->
    <div role="main">
      <router-view></router-view>
    </div>
    <!-- /.main -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Jumbotron from "@/components/Jumbotron.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Jumbotron,
    Sidebar,
    Footer,
  },
};
</script>

<style></style>
