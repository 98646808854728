<template>
  <div id="Home">
    <!-- <Carousel /> -->
    <Jumbotron heading="Home" subheading="" />
    <b-container>
      <b-row>
        <div class="col-lg-8 blog-main pt-5">
          <About />
        </div>
        <Sidebar />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import Jumbotron from "@/components/Jumbotron.vue";
import About from "@/components/About.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "Home",
  components: {
    Carousel,
    Jumbotron,
    About,
    Sidebar,
  },
};
</script>
