<template>
  <div id="Carousel">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      fade
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- <b-carousel-slide
        caption="Upcoming Events"
        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
        img-src="https://picsum.photos/1024/480/?image=52"
      ></b-carousel-slide> -->
      <div v-for="image in images" :key="image.id">
        <b-carousel-slide
          caption=""
          text=""
          :img-src="require('@/assets/images/' + image.source + '')"
          fluid
        >
        </b-carousel-slide>
      </div>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      slide: 0,
      sliding: null,

      images: [
        {
          id: 0,
          source: "ocean.jpg",
        },
        {
          id: 1,
          source: "tree.jpeg",
        },
        {
          id: 2,
          source: "bible-study.jpeg",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style></style>
