<template>
  <div id="EventPage">
    <Jumbotron heading="About The Event" subheading="" />
    <b-container>
      <b-row>
        <div class="col-lg-8 blog-main pt-5">
          <Event />
        </div>
        <Sidebar />
      </b-row>
      <hr />
    </b-container>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";
import Event from "@/components/Event.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "EventPage",
  components: {
    Jumbotron,
    Event,
    Sidebar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
