<template>
  <div id="FilterResults">
    <b-row class="mb-4">
      <b-col class="d-flex justify-content-between">
        <!-- Type Options -->
        <b-form inline>
          <label>
            Type:&nbsp;&nbsp;
          </label>
          <b-form-select
            class="btn border-secondary text-dark"
            :value="sermonType"
            :options="sermonTypeOptions"
            @input="changeSermonType"
          >
          </b-form-select>
        </b-form>
        <!-- End Type Options -->

        <!-- Per Page Options -->
        <b-form inline>
          <label>
            Per Page:&nbsp;&nbsp;
          </label>
          <b-form-select
            class="btn border-secondary text-dark"
            :value="perPage"
            :options="perPageOptions"
            @input="changePerPage"
          >
          </b-form-select>
        </b-form>
        <!-- End Per Page Options -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FilterResults",
  props: ["sermonType", "sermonTypeOptions", "perPage", "perPageOptions"],
  methods: {
    changeSermonType(event) {
      this.$emit("sermonTypeChanged", event);
    },
    changePerPage(event) {
      this.$emit("perPageChanged", event);
    },
  },
};
</script>

<style></style>
