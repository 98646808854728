<template>
  <div id="Pagination">
    <b-row>
      <b-col>
        <b-pagination
          :value="currentPage"
          :total-rows="paginationRows"
          :per-page="perPage"
          :align="align"
          :aria-controls="ariaControls"
          @input="changePage"
        >
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["currentPage", "paginationRows", "perPage", "align", "ariaControls"],
  methods: {
    changePage(event) {
      this.$emit("pageChanged", event);
    },
  },
};
</script>

<style></style>
