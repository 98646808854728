<template>
  <div id="Event">
    <div v-if="eventIsLoaded">
      <h1>{{ event.name }}</h1>
      <br />
      <b-tabs content-class="mt-3">
        <b-tab title="Details" active>
          <div v-if="event.message === ''">
            No details provided.
          </div>
          <div v-else>
            <p>{{ event.message }}</p>
          </div>
        </b-tab>
        <b-tab title="Photos">
          <p>
            There are no photos available at this time.
          </p>
        </b-tab>
        <b-tab title="Videos">
          <p>
            There are no videos available at this time.
          </p>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else-if="apiError">
      Error, could not get event details!
    </div>
    <div v-else>
      <b-skeleton width="75%" height="75%"></b-skeleton>
      <br />
      <b-tabs content-class="mt-3">
        <b-tab title="Details" disabled> </b-tab>
        <b-tab title="Photos" disabled> </b-tab>
        <b-tab title="Videos" disabled> </b-tab>
      </b-tabs>
      <br />
      <b-skeleton width="50%"></b-skeleton>
      <b-skeleton width="50%"></b-skeleton>
      <b-skeleton width="50%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Event",
  data() {
    return {
      apiError: false,

      event: [],
      eventIsLoaded: false,
    };
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    async getEvent() {
      await axios
        .get(`/api/v1/event/${this.$route.params.event}`)
        .then(res => {
          this.event = res.data[0];
          console.log(this.event);
          this.eventIsLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiError = true;
        });
    },
  },
};
</script>

<style></style>
