<template>
  <div id="RegistrationForm">
    <b-img
      :src="require('@/assets/images/events/2024-womens-retreat.jpg')"
      fluid
      alt="Responsive image"
      rounded
    >
    </b-img>

    <br /><br />

    <h2>Event Details</h2>
    <p class="text-justify">
      Welcome to the 2024 Women's Retreat! The retreat will take place on Sat,
      Jun 01, 2024 at Indian Grill. Get ready to unwind, participate in fun
      activities, enjoy delicious food, and make new friends. Don't miss out on
      this incredible event - mark your calendars now!
    </p>

    <p>
      <font-awesome-icon :icon="['fas', 'bible']" />
      &nbsp;&nbsp; The Mountain of God &bullet; Genesis 22
      <br />
      <font-awesome-icon :icon="['fas', 'user-alt']" />
      &nbsp;&nbsp; Sister Susan Bushi &bullet; New Jersey
    </p>
    <br />

    <h2>Date and Time</h2>
    <p>
      <font-awesome-icon :icon="['fas', 'calendar-check']" />
      &nbsp;&nbsp; Saturday, June 1 &bullet; 10:30am - 2:30pm EST
    </p>
    <br />

    <h2>Location</h2>
    <p>
      <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
      &nbsp;&nbsp; Indian Grill &bullet;
      <a href="https://maps.app.goo.gl/mKbBWyr4ZY36FnGu9">
        969 Bethlehem Pike Montgomeryville, PA 18936
      </a>
    </p>
    <br />

    <!-- Registration Form Status -->
    <div v-if="status == 0">
      <p>
        <font-awesome-icon
          :icon="['fas', 'circle-xmark']"
          class="text-danger"
        />
        &nbsp;&nbsp; Registration is closed!
      </p>
    </div>
    <div v-else-if="status == 1">
      <p>
        <font-awesome-icon
          :icon="['fas', 'hourglass-half']"
          class="text-warning"
        />
        &nbsp;&nbsp; Registration will close soon!
      </p>
    </div>
    <div v-else-if="status == 2">
      <p>
        <font-awesome-icon
          :icon="['fas', 'circle-check']"
          class="text-success"
        />
        &nbsp;&nbsp; Registration is live!
      </p>
    </div>
    <!-- End Registration Form Status -->

    <div v-if="canRegister">
      <h2>Register</h2>
      <b-form @submit="onSubmit" @reset="onReset" novalidate>
        <b-row>
          <b-col>
            <b-form-group
              id="firstName-group"
              label="First Name:"
              label-for="firstName-input"
              description="Required"
            >
              <b-form-input
                id="firstName-input"
                v-model="form.firstName"
                placeholder="Enter your first name"
                :state="firstNameValidated"
                @change="onFirstNameChange"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="lastName-group"
              label="Last Name:"
              label-for="lastName-input"
              description="Required"
            >
              <b-form-input
                id="lastName-input"
                v-model="form.lastName"
                placeholder="Enter your last name"
                :state="lastNameValidated"
                @change="onLastNameChange"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-form-group
              id="email-group"
              label="Email:"
              label-for="email-input"
              description="Required"
            >
              <b-form-input
                id="email-input"
                type="email"
                v-model="form.email"
                placeholder="Enter your email address"
                :state="emailValidated"
                @change="onEmailChange"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-form-group label="Please check the appropriate boxes:">
              <b-form-checkbox-group
                v-model="form.selected"
                :options="options"
                stacked
                @change="onCheckboxChange"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-form-group
              v-if="showGuestsInput"
              id="guests-group"
              label="Guests:"
              label-for="guests-input"
              description="Required"
            >
              <b-form-select
                id="guests-input"
                type="number"
                v-model="form.guests"
                placeholder="Please enter the number of guests you will be bringing"
                :state="guestsValidated"
                required
              >
                <b-form-select-option
                  v-for="guest in 9"
                  :key="guest"
                  :value="guest"
                  >{{ guest }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <br /><br />
        <b-button type="submit" variant="primary" :disabled="isSubmitting">
          <div v-if="isSubmitting">
            <b-spinner small></b-spinner>
          </div>
          <div v-else>
            Submit
          </div>
        </b-button>
        &nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
      <br />
      <h3 v-if="submissionError === true" class="text-danger" dismissible>
        {{ submissionMessage }}
      </h3>
      <h3 v-if="submissionError === false" class="text-success" dismissible>
        {{ submissionMessage }}
      </h3>
    </div>
  </div>
</template>

<script>
let axios = require("axios");
let moment = require("moment");

export default {
  name: "RegistrationForm",
  components: {},
  data() {
    return {
      eventDate: "2024-06-02",
      currentDate: moment().format("YYYY-MM-DD"),
      status: 0,
      canRegister: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        selected: [],
        guests: null,
      },
      options: [
        { value: "attending", text: "Yes, I will attend this event" },
        { value: "sharing", text: "Yes, I want to share my testimony" },
        { value: "singing", text: "Yes, I want to sing a song" },
        { value: "guest", text: "Yes, I will bring a guest(s)" },
      ],
      isLoading: false,
      isSubmitting: false,

      submissionError: null,
      submissionMessage: "",

      firstNameValidated: null,
      lastNameValidated: null,
      emailValidated: null,
      guestsValidated: null,
      formHasErrors: false,
      showGuestsInput: false,
    };
  },
  mounted() {
    this.setStatus();
  },
  watch: {
    "form.email"(val) {
      val.replace(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "");
    },
  },
  methods: {
    setStatus() {
      if (
        // Before
        this.currentDate <
        moment(this.eventDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ) {
        this.status = 2;
        this.canRegister = true;
      } else if (
        // Same Day
        this.currentDate ===
        moment(this.eventDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ) {
        this.status = 1;
        this.canRegister = true;
      } else if (
        // After
        this.currentDate >
        moment(this.eventDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ) {
        this.status = 0;
        this.canRegister = false;
      }
    },
    onCheckboxChange() {
      if (this.form.selected.includes("guest")) {
        this.showGuestsInput = true;
        this.guestIsRequired = true;
      } else {
        this.showGuestsInput = false;
        this.guestIsRequired = false;
      }
    },
    async onSubmit(event) {
      event.preventDefault();
      this.isSubmitting = true;

      let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      // Form Validation
      // First Name
      if (this.form.firstName !== "") {
        this.firstNameValidated = true;
      } else {
        this.firstNameValidated = false;
      }
      // Last Name
      if (this.form.lastName !== "") {
        this.lastNameValidated = true;
      } else {
        this.lastNameValidated = false;
      }
      // Email
      if (this.form.email !== "") {
        this.emailValidated = true;
      } else {
        this.emailValidated = false;
      }

      if (
        !this.firstNameValidated &&
        !this.lastNameValidated &&
        !this.emailValidated
      ) {
        this.formHasErrors = true;
      } else if (
        this.firstNameValidated &&
        this.lastNameValidated &&
        this.emailValidated
      ) {
        this.formHasErrors = false;
      }

      if (this.formHasErrors) {
        // Can't continue
        this.isSubmitting = false;
      } else {
        this.isSubmitting = true;

        let formData = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          selected: this.form.selected,
          guests: this.form.guests,
        };

        await axios
          .post(`/api/v1/registrations/submit`, formData)
          .then(res => {
            if (res.data.status === "success") {
              // this.$bvToast.toast(res.data.message, {
              //   title: "Success!",
              //   variant: "success",
              //   toaster: "b-toaster-top-center",
              //   solid: true,
              // });
              this.submissionError = false;
              this.submissionMessage = res.data.message;
            }
          })
          .catch(err => {
            console.log(err);
            this.submissionError = true;
            this.submissionMessage = res.data.message;
          });
        this.isSubmitting = false;
      }
    },
    onReset(event) {
      event.preventDefault();

      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.selected = [];
      this.form.guests = 0;

      this.firstNameValidated = null;
      this.lastNameValidated = null;
      this.emailValidated = null;
      this.guestsValidated = null;
      this.formHasErrors = false;
      this.showGuestsInput = false;

      this.submissionError = null;
      this.submissionMessage = "";
    },
    onFirstNameChange(event) {
      if (this.form.firstName == "") {
        this.firstNameValidated = false;
      } else this.firstNameValidated = true;
    },
    onLastNameChange(event) {
      if (this.form.lastName == "") {
        this.lastNameValidated = false;
      } else this.lastNameValidated = true;
    },
    onEmailChange(event) {
      if (this.form.email == "") {
        this.emailValidated = false;
      } else this.emailValidated = true;
    },
  },
  computed: {},
};
</script>

<style></style>
