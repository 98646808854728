export default {
  data() {
    return {
      verses: [
        {
          book: "Jeremiah",
          chapter: "29",
          verse: "12",
          passage:
            "Then shall ye call upon me, and ye shall go and pray unto me, and I will hearken unto you.",
        },
        {
          book: "Psalms",
          chapter: "145",
          verse: "18",
          passage:
            "The Lord is near to all who call on him, to all who call him in truth.",
        },
        {
          book: "Phillipians",
          chapter: "4",
          verse: "6",
          passage:
            "Do not be anxious about anything, but in every situation, by prayer and petition, with thanksgiving, present your requests to God. ",
        },
        {
          book: "Colossians",
          chapter: "4",
          verse: "2",
          passage: "Devote yourselves to prayer, being watchful and thankful.",
        },
      ],
    };
  },
};
