<template>
  <div id="RecentEvents">
    <h4 class="font-italic">Recent Events</h4>
    <hr />
    <div v-if="recentEventsAreLoaded">
      <div v-for="event in recentEvents" :key="event.id">
        <ol class="list-unstyled mb-0">
          <li>
            {{ event.name }}
          </li>
        </ol>
      </div>
      <br />
      <router-link to="/events" class="btn btn-outline-primary">
        View all
      </router-link>
    </div>
    <div v-else-if="apiError">
      <p>Error, could not get recent events!</p>
    </div>
    <div v-else>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RecentEvents",
  data() {
    return {
      apiError: false,

      recentEvents: [],
      recentEventsAreLoaded: false,
    };
  },
  mounted() {
    this.getRecentEvents();
  },
  methods: {
    async getRecentEvents() {
      await axios
        .get(`/api/v1/events`, {
          params: {
            direction: "desc",
            limit: 5,
          },
        })
        .then(res => {
          this.recentEvents = res.data;
          this.recentEventsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiError = true;
        });
    },
  },
};
</script>

<style></style>
