<template>
  <div id="AllEvents">
    <div v-if="eventsAreLoaded">
      <Search :search="search" @searchChanged="search = $event" />
      <!-- <FilterResults
        :sermonType="sermonType"
        :sermonTypeOptions="sermonTypeOptions"
        @sermonTypeChanged="sermonType = $event"
        :perPage="perPage"
        :perPageOptions="perPageOptions"
        @perPageChanged="perPage = $event"
      /> -->
      <Pagination
        :currentPage="currentPage"
        :paginationRows="paginationRows"
        :perPage="perPage"
        align="fill"
        ariaControls="events"
        @pageChanged="currentPage = $event"
      />
      <div id="events" class="row row-cols-1 row-cols-md-2">
        <div v-for="event in filteredEvents" :key="event.id">
          <div class="col mb-4">
            <div class="card h-100">
              <router-link :to="'/event/' + event.slug">
                <img
                  src="https://via.placeholder.com/300x150"
                  class="card-img-top"
                  alt=""
                />
              </router-link>
              <div class="card-body">
                <h3 class="card-title">
                  <router-link :to="'/event/' + event.slug">
                    {{ event.name }}
                  </router-link>
                </h3>
                <p class="card-text">{{ event.preview }}</p>
              </div>
              <div class="card-footer">
                <small class="text-muted">{{ event.created_at }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="apiError">
      Error, could not get events!
    </div>
    <div v-else>
      <b-input-group class="mb-4">
        <b-form-input
          v-model="search"
          placeholder="Search"
          disabled
        ></b-form-input>
      </b-input-group>
      <div class="row row-cols-1 row-cols-md-2">
        <div class="col mb-4">
          <div class="card h-100">
            <a href="#">
              <b-skeleton-img></b-skeleton-img>
            </a>
            <div class="card-body">
              <h3 class="card-title"><b-skeleton width="50%"></b-skeleton></h3>
              <p class="card-text"><b-skeleton width="40%"></b-skeleton></p>
            </div>
            <div class="card-footer">
              <small class="text-muted"
                ><b-skeleton width="65%"></b-skeleton
              ></small>
            </div>
          </div>
        </div>

        <div class="col mb-4">
          <div class="card h-100">
            <a href="#">
              <b-skeleton-img></b-skeleton-img>
            </a>
            <div class="card-body">
              <h3 class="card-title"><b-skeleton width="50%"></b-skeleton></h3>
              <p class="card-text"><b-skeleton width="40%"></b-skeleton></p>
            </div>
            <div class="card-footer">
              <small class="text-muted"
                ><b-skeleton width="65%"></b-skeleton
              ></small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import FilterResults from "@/components/FilterResults.vue";
import Pagination from "@/components/Pagination.vue";

import axios from "axios";

export default {
  name: "AllEvents",
  components: {
    Search,
    FilterResults,
    Pagination,
  },
  data() {
    return {
      apiError: false,

      events: [],
      eventsAreLoaded: false,

      search: "",

      perPage: 10,
      perPageOptions: ["10", "15", "20"],

      // Pagination
      currentPage: 1,
    };
  },
  mounted() {
    this.getAllEvents();
  },
  methods: {
    async getAllEvents() {
      await axios
        .get(`/api/v1/events`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.events = res.data;
          this.eventsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiError = true;
        });
    },
  },
  computed: {
    filteredEvents() {
      return this.events.filter(event => {
        return event.name.toLowerCase().match(this.search.toLowerCase());
      });
    },
    paginationRows() {
      return this.filteredEvents.length;
    },
  },
};
</script>

<style></style>
