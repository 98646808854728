<template>
  <header>
    <div class="container">
      <header class="blog-header py-3">
        <div class="row flex-nowrap justify-content-between align-items-center">
          <div class="text-center">
            <a class="blog-header-logo text-dark" href="/">
              God Answers Prayer Group
            </a>
          </div>
        </div>
      </header>
      <div class="nav-scroller py-1 mb-2">
        <nav class="nav d-flex justify-content-center">
          <router-link to="/" class="nav-link text-muted">Home</router-link>
          <!-- <router-link to="/events" class="nav-link text-muted">
            Events
          </router-link> -->
          <router-link to="/sermons" class="nav-link text-muted">
            Sermons
          </router-link>
          <!-- <router-link to="/womens-retreat" class="nav-link text-muted">
            Womens Retreat
          </router-link> -->
          <!-- <router-link to="/donate" class="nav-link text-muted">
            Donate
          </router-link> -->
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
