<template>
  <div id="RecentSermons">
    <h4 class="font-italic">Recent Sermons</h4>
    <hr />
    <div v-if="recentSermonsAreLoaded">
      <div v-for="sermon in recentSermons" :key="sermon.id">
        <ol class="list-unstyled mb-0">
          <li>
            {{ sermon.name }}
          </li>
        </ol>
      </div>
      <br />
      <router-link to="/sermons" class="btn btn-outline-primary">
        View all
      </router-link>
    </div>
    <div v-else-if="apiError">
      <p>Error, could not find recent sermons!</p>
    </div>
    <div v-else>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RecentSermons",
  data() {
    return {
      apiError: false,

      recentSermons: [],
      recentSermonsAreLoaded: false,
    };
  },
  mounted() {
    this.getRecentSermons();
  },
  methods: {
    async getRecentSermons() {
      await axios
        .get(`/api/v1/sermons`, {
          params: {
            direction: "desc",
            limit: 5,
          },
        })
        .then(res => {
          this.recentSermons = res.data;
          this.recentSermonsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiError = true;
        });
    },
  },
};
</script>

<style></style>
