<template>
  <div id="about">
    <h1>Livestream</h1>
    <hr />
    <div>
      <b-aspect :aspect="'16:9'">
        <b-embed
          type="iframe"
          src="https://www.youtube.com/embed/So2nvN1tY6Q"
          allowfullscreen
          width="100%"
          height="500px;"
          fluid
        >
        </b-embed>
      </b-aspect>
    </div>
    <br />

    <h1>Who We Are</h1>
    <hr />

    <div class="text-justify">
      <p>
        Prayer Group of Philadelphia was a burden given to us by God to pray for
        one another. God has opened a door for us in an amazing way to reach
        unreached. We started the prayer group in 2012 and at present we have a
        group of people continuously and consistently spending time in prayer.
      </p>
      <p>
        Many of our prayers were answered and we have great experiences of
        walking with God, speaking with God and receiving from God.
      </p>
      <p>
        We are greatly supported by one another through prayers and word. We
        pray that everyone will be blessed by the messages we post on this
        website. God Bless you all. Please share any prayer concerns. We will
        lift up your request in our daily prayers.
      </p>
    </div>
    <br />

    <h2>How to Join</h2>
    <hr />
    <p>To connect via telephone:</p>
    <ol>
      <li>
        Call <a href="tel:+5156049793"><strong>515‐604‐9793</strong></a>
      </li>
      <li>Enter Access Code: <strong>926592</strong></li>
      <li>Announce Your Presence!</li>
    </ol>
    <br />

    <h2>Schedule</h2>
    <hr />
    <h5>Weekly Prayer Conference:</h5>
    <p>Friday: 9PM-10:30PM</p>
    <h5>Weekly Ministries:</h5>
    <p>
      Monday-Friday: 5AM-6AM
      <br />
      Morning meditations and prayer (Word shared by Bro. Prakash Raj)
    </p>
    <p>
      Monday: 7PM-8PM
      <br />
      Sister Hepzibah will share the word.
    </p>
    <h5>Afternoon Prayers (2PM-3PM):</h5>
    <table>
      <tr>
        <td class="float-right">Monday:</td>
        <td class="pl-3">Prayer for children</td>
      </tr>
      <tr>
        <td class="float-right">Tuesday:</td>
        <td class="pl-3">Prayer for Young Adults</td>
      </tr>
      <tr>
        <td class="float-right">Wednesday:</td>
        <td class="pl-3">Prayer for sick people</td>
      </tr>
      <tr>
        <td class="float-right">Thursday:</td>
        <td class="pl-3">Prayer for Pregnant sisters</td>
      </tr>
      <tr>
        <td class="float-right">Friday:</td>
        <td class="pl-3">Prayer for Salvation</td>
      </tr>
    </table>

    <br />
    <p>Please join us and be blessed.</p>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style></style>
