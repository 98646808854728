<template>
  <div id="Footer" class="pt-5">
    <footer class="blog-footer" id="footer">
      <b-container>
        <b-row class="py-2">
          <b-col class="d-flex justify-content-center">
            <p class="px-2">
              Copyright &copy; God Answers Prayer Group. {{ date }}.
            </p>
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col class="d-flex justify-content-center">
            <!-- &bullet; -->
            <p class="px-2">
              <a href="https://dashboard.godanswersprayer.org/" target="_blank">
                Login
              </a>
            </p>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style></style>
