<template>
  <div id="EventsPage">
    <Jumbotron heading="Events" subheading="" />
    <b-container>
      <b-row>
        <div class="col-lg-8 blog-main pt-5">
          <AllEvents />
        </div>
        <Sidebar />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";
import AllEvents from "@/components/AllEvents.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "EventsPage",
  components: {
    Jumbotron,
    AllEvents,
    Sidebar,
  },
};
</script>

<style></style>
