<template>
  <div id="Error404Page">
    <Jumbotron heading="Error 404" subheading="Page Not Found" />
    <b-container>
      <b-row>
        <div class="col-lg-8 blog-main pt-5">
          That page could not be found! Please check your url carefully!
        </div>
        <Sidebar />
      </b-row>
      <hr />
    </b-container>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "Error404Page",
  components: {
    Jumbotron,
    Sidebar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
