<template>
  <div id="AllSermons">
    <div v-if="sermonTypesAreLoaded">
      <div v-if="sermonsAreLoaded">
        <Search :search="search" @searchChanged="search = $event" />
        <FilterResults
          :sermonType="sermonType"
          :sermonTypeOptions="sermonTypeOptions"
          @sermonTypeChanged="sermonType = $event"
          :perPage="perPage"
          :perPageOptions="perPageOptions"
          @perPageChanged="perPage = $event"
        />
        <Pagination
          :currentPage="currentPage"
          :paginationRows="paginationRows"
          :perPage="perPage"
          align="fill"
          ariaControls="events"
          @pageChanged="currentPage = $event"
        />
      </div>
      <div v-else>
        <b-input-group class="mb-4">
          <b-form-input disabled></b-form-input>
        </b-input-group>
        <b-row class="mb-4">
          <b-col class="d-flex justify-content-between">
            <!-- Type Options -->
            <b-form inline>
              <label class="">
                Type:&nbsp;&nbsp;
              </label>
              <b-form-select
                disabled
                class="btn border-secondary text-dark"
                v-model="sermonType"
                :options="sermonTypeOptions"
              >
              </b-form-select>
            </b-form>
            <!-- End Type Options -->

            <!-- Per Page Options -->
            <b-form inline>
              <label class="">
                Per Page:&nbsp;&nbsp;
              </label>
              <b-form-select
                disabled
                class="btn border-secondary text-dark"
                v-model="perPage"
                :options="perPageOptions"
              >
              </b-form-select>
            </b-form>
            <!-- End Per Page Options -->
          </b-col>
        </b-row>
        <b-skeleton type="input"></b-skeleton>
        <br />
      </div>
      <br />
    </div>
    <div v-else-if="apiErrorSermonTypes">
      Error, could not get sermon types!
      <br />
      <br />
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <b-skeleton type="input"></b-skeleton>
        </b-col>
      </b-row>
      <br />
      <br />
    </div>
    <div v-if="sermonsAreLoaded">
      <b-row>
        <div
          id="sermons"
          v-for="sermon in filteredSermons.slice(
            (currentPage - 1) * perPage,
            currentPage * perPage
          )"
          :key="sermon.id"
          class="pb-3"
        >
          <b-card>
            <div
              v-if="sermon.type === 'Fasting Prayer'"
              class="small text-info"
            >
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <div
              v-if="sermon.type === 'Bible Study'"
              class="small text-success"
            >
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <div
              v-if="sermon.type === 'Early Morning Weekday'"
              class="small text-warning"
            >
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <div
              v-if="sermon.type === 'Friday Night'"
              class="small text-primary"
            >
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <div v-if="sermon.type === 'Special'" class="small text-secondary">
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <div v-if="sermon.type === 'Testimonies'" class="small text-danger">
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <div
              v-if="sermon.type === 'Sunday Morning'"
              class="small text-warning"
            >
              <font-awesome-icon class="" :icon="['fas', 'bible']" />
              &nbsp;&nbsp;
              {{ sermon.type }}
            </div>
            <h3 id="sermonName" class="pt-2">{{ sermon.name }}</h3>
            <p id="sermonDate" class="text-muted">
              {{ sermon.date }}
            </p>
            <audio
              :src="sermon.url"
              type="audio/mpeg"
              controls
              style="width: 100%;"
            ></audio>
          </b-card>
        </div>
      </b-row>
    </div>
    <div v-else-if="apiErrorSermons">
      Error, could not get sermons!
    </div>
    <div v-else>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="35%"></b-skeleton>
      <br />
      <b-skeleton type="input"></b-skeleton>
      <br />
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="35%"></b-skeleton>
      <br />
      <b-skeleton type="input"></b-skeleton>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import FilterResults from "@/components/FilterResults.vue";
import Pagination from "@/components/Pagination.vue";

let axios = require("axios");

export default {
  name: "AllSermons",
  components: {
    Search,
    FilterResults,
    Pagination,
  },
  data() {
    return {
      apiErrorSermons: false,
      apiErrorSermonTypes: false,

      sermons: [],
      sermonsAreLoaded: false,

      search: "",

      sermonTypesAreLoaded: false,
      sermonType: "All",
      sermonTypeOptions: ["All"],

      perPage: 10,
      perPageOptions: ["10", "15", "20"],

      // Pagination
      currentPage: 1,
    };
  },
  mounted() {
    this.getAllSermons();
    this.getSermonTypes();
  },
  methods: {
    async getAllSermons() {
      await axios
        .get(`/api/v1/sermons`, {
          params: {
            direction: "desc",
          },
        })
        .then(res => {
          this.sermons = res.data;
          this.sermonsAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermons = true;
        });
    },
    async getSermonTypes() {
      await axios
        .get(`/api/v1/types/sermons`)
        .then(res => {
          res.data.map(sermonType => {
            this.sermonTypeOptions.push(sermonType);
          });
          this.sermonTypesAreLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.apiErrorSermonTypes = true;
        });
    },
  },
  computed: {
    filteredSermons() {
      if (this.sermonType !== "All") {
        return this.sermons.filter(sermon => {
          return (
            sermon.name.toLowerCase().match(this.search.toLowerCase()) &&
            sermon.type === this.sermonType
          );
        });
      } else {
        return this.sermons.filter(sermon => {
          return sermon.name.toLowerCase().match(this.search.toLowerCase());
        });
      }
    },
    paginationRows() {
      return this.filteredSermons.length;
    },
  },
};
</script>

<style></style>
