<template>
  <div id="SermonsPage">
    <Jumbotron heading="Sermons" subheading="" />
    <b-container>
      <b-row>
        <div class="col-lg-8 blog-main pt-5">
          <AllSermons />
        </div>
        <Sidebar />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";
import AllSermons from "@/components/AllSermons.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "SermonsPage",
  components: {
    Jumbotron,
    AllSermons,
    Sidebar,
  },
};
</script>

<style></style>
