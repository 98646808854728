<template>
  <div id="DailyVerse">
    <h4 class="font-italic">Daily Verse</h4>
    <blockquote>
      <blockquote>"{{ verse.passage }}"</blockquote>
      <br />
      <footer class="blockquote-footer">
        {{ verse.book }} {{ verse.chapter }}:{{ verse.verse }}
      </footer>
    </blockquote>
  </div>
</template>

<script>
// Mixins
import verses from "@/mixins/verses.js";

export default {
  mixins: [verses],
  name: "DailyVerse",
  data() {
    return {
      verse: {},
    };
  },
  mounted() {
    this.getRandomVerse();
  },
  methods: {
    getRandomVerse() {
      this.verse = this.verses[Math.floor(Math.random() * 4)];
    },
  },
};
</script>

<style></style>
