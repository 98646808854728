<template>
  <div class="jumbotron jumbotron-fluid py-5 text-white bg-dark">
    <b-container>
      <h1 class="display-4 font-italic">{{ heading }}</h1>
      <p class="lead my-3">{{ subheading }}</p>
      <!-- <p class="lead mb-0"><a href="#" class="text-white font-weight-bold">Continue reading...</a></p> -->
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Jumbotron",
  props: ["heading", "subheading"],
};
</script>

<style></style>
