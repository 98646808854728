<template>
  <div id="RegisterPage">
    <Jumbotron heading="2024 Women's Retreat" subheading="Register below!" />
    <b-container>
      <b-row>
        <div class="col-lg-8 blog-main pt-5">
          <RegistrationForm />
        </div>
        <Sidebar />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Jumbotron from "@/components/Jumbotron.vue";
import RegistrationForm from "@/components/RegistrationForm.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "RegisterPage",
  components: {
    Jumbotron,
    RegistrationForm,
    Sidebar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
