<template>
  <!-- Blog Sidebar -->
  <aside class="col-lg-4 blog-sidebar pt-5">
    <div class="position-sticky" style="top: 2rem;">
      <div class="p-4 mb-3 bg-light rounded">
        <DailyVerse />
      </div>

      <div class="p-4">
        <RecentSermons />
      </div>

      <!-- <div class="p-4">
        <RecentEvents />
      </div> -->

      <div class="p-4">
        <SocialMedia />
      </div>
    </div>
  </aside>
  <!-- /.blog-sidebar -->
</template>

<script>
import DailyVerse from "@/components/DailyVerse.vue";
import RecentSermons from "@/components/RecentSermons.vue";
import RecentEvents from "@/components/RecentEvents.vue";
import SocialMedia from "@/components/SocialMedia.vue";

export default {
  name: "Sidebar",
  components: {
    DailyVerse,
    RecentSermons,
    RecentEvents,
    SocialMedia,
  },
};
</script>

<style></style>
